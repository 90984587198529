




















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
// import { Picker } from "emoji-mart-vue";

@Component({
  components: {
    // Picker  
  }
})
export default class faceLink extends Vue {
  @Prop({ default: true }) private dialogFormVisible!: boolean;
  public form: any = {};

 
  public rules: any = {
      name: [
        { required: true, message: '请输入文本内容', trigger: ['blur','change'] }],
      link: [
        { required: true, message: '请输入链接地址', trigger:  ['blur','change'] }
      ],          
  }
  public submitForm(formName:any) : void{
      const addRef = (this.$refs.form as any)  
      addRef.validate( async (valid: any) => {
          if (valid) {
            this.selectVal()
            this.cancel()
          } else {
            console.log('error submit!!');
            return false;
          }
      });
  }

  @Emit('selectVal')
  public selectVal(): Boolean {
    return this.form
  }

  @Emit('selectChange')
  public cancel(): Boolean {
    return false
  }


}
