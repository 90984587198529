






















































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { WechatMenuService } from '@/services/wechat-menu-service';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import face from '@/components/face/face.vue';
import wechatImageText from '@/components/wechatImageText/index.vue';
import { cosh } from 'core-js/library/fn/math';

@Component({
  components: {
    wechatImageText,
    face
  }
})
export default class wechatMenu extends Vue {
  public labelPosition: String = 'top';
  public form: any = {
    content:'',
    name:'',
    num:'1',
    key:'text',
    type:'click',
    selectKey:'text'
  };
  public selectKey:string='text';
  public historyLink:string ='';
  public textMessage: any = '';
  public urlAdress: any = '';
  public imageValue: any = '';
  public subMenus: any[] = []; //拼接子菜单
  public rules: any = {
      name: [
        { required: true, message: '请输入菜单名称', trigger: ['blur','change'] }],
      num: [
        { required: true, message: '请输入子菜单排序', trigger:  ['blur','change'] }
      ],      
      replay: [
        { required: true, validator: this.checks, trigger: ['blur','change'] }
      ]
  }
  public dialogFormVisible: boolean = false;//是否显示弹框
  public readonly: boolean = true;
  public title: String = "选择图文素材";
  public agentId: any = "1";
  public loading: boolean = false;
  public showFrom: Boolean = false;
  public showReplay: Boolean = true;
  public menuList: any[] = [];//存储菜单集合
  public count:number = 0;
  public tableData: any[] = [];
  public applications: any[] = [];
  public ishowImage: Boolean = false;//默认是没有选中数据tableData==0
  
  @Inject(WechatMenuService) private wechatMenuService!: WechatMenuService;
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;

  @Watch('menuList', { deep: true })
  handleWatch(val:any){  
    if (val) {
      this.count++
    }    
	}

 @Watch("form.content", {deep: true })
  handleContent(val:any) {
    const clearRef = (this.$refs.form as any) 
     if(val !='' && clearRef!=undefined){         
        clearRef.clearValidate('replay'); 
      }
  }

  public checks (rule:any, value:any, callback:any) {
    if(this.selectKey=='text' && this.form.content === ''){
       callback(new Error('请输入文字消息'));
    }else if(this.selectKey=='news' && (this.form.articleId === '' || this.form.articleId === 0) ){
       callback(new Error('请选择图文'));
    } else if(this.selectKey=='link' && this.form.link === ''){
       callback(new Error('请输入链接'));
    }else if(this.selectKey=='action' && this.form.action === ''){
       callback(new Error('请选择微信功能'));
    }else {
      callback();
    }
  }

  public created(): void {
    if(this.$route.query.id){
      this.agentId = this.$route.query.id
      this.historyLink = `${location.origin}/historynew-wecom-front/?agentId=`+this.agentId
    }
    this.applicationsList()
    this.wxmenuGet()  
  }
  private childChange (val: any, event: any) {
    this.form.content = val
  }
  public async articleGet() :Promise<void>{     
    const res = await this.wechatImageTextService.articleGet(this.form.articleId); 
    this.formData(res) 
    this.ishowImage = true     
  }
  public async actionChanged(val: any){ 
    this.form.type = val    
  }
  //处理专栏id对应的name
  public async formData(res: any) {
    let data = res   
    for (var item = 0; item < data.length; item++) {
      for (var i = 0; i < this.applications.length; i++) {   
        if (this.applications[i].channelAgentId == data[item].agentId) {        
          data[item].agentName = this.applications[i].name
        }
      }
    }
    this.tableData = data
  }
  //获取专栏
  public async applicationsList(): Promise<void> {
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res
  }
  //查询指定专栏的菜单
  public async wxmenuGet(): Promise<void>{
    const res = await this.wechatMenuService.wxmenuGet(this.agentId); 
    await this.formDataShow(res)    
  }
  //回显数据
  public async formDataShow(res: any) {
    let data = res
    let arr = res
    for (var item = 0; item < data.length; item++) {
      let ids = data[item].article_id     
      data[item].selectKey = data[item].select_key; 
      data[item].articleId = ids ==undefined ? '' : ids  
      if(data[item].subMenus!=undefined){
         for (var i = 0; i < data[item].subMenus.length; i++) {
            data[item].subMenus[i].selectKey = data[item].subMenus[i].select_key;
            let imageId = data[item].subMenus[i].article_id
            data[item].subMenus[i].articleId = imageId == undefined ? '' : imageId ;
          }
      }
      
    }
    this.menuList = data   
  }
  //添加菜单
  public async menuAdd(id:any,index:any){        
    if(id=='1' ){ //添加主菜单
      this.parentMenu()
    }
    if(id!='1'){    //添加子菜单 
       this.childMenu(id,index)
    }  
    
  }
  public parentMenu(){
     let arr= this.menuList    
      if( this.menuList.length>=3){    
        this.$message.error('最多只能添加3项主菜单!');
        return;   
      }else{
        if(this.showFrom == true){
            const addRef = (this.$refs.form as any) 
            addRef.validate( async (valid: any) => {
                if (valid) {         
                  for(let i=0;i<arr.length;i++) {                                                 
                      this.resetForm()  
                      this.form.name = '主菜单'                                
                      this.menuList.push(this.form)                                   
                      break;                                                                   
                  }      
                } else {
                  console.log('error submit!!');
                  return false;
                }
            });   
        }else{
           this.showFrom = true;   
           this.resetForm()  
           this.form.name = '主菜单'
           this.menuList.push(this.form)       
        }
       
      }     
  } 
  
  public childMenu(id:any,index:any){
    let arr= this.menuList  
    this.showReplay = true;  
    if(id.subMenus !=undefined && id.subMenus.length>=5){    
          this.$message.error('最多只能添加5项子菜单!');
          return;   
      }else{
         if(this.showFrom == true){
              const addRef = (this.$refs.form as any) 
              addRef.validate( async (valid: any) => {
                  if (valid) {                      
                    for(let i=0;i<arr.length;i++) {
                      if(index == i){
                          if(this.menuList[i].subMenus==undefined) {
                            this.menuList[i].subMenus = []
                          }   
                        this.resetForm()
                        this.form.name = '子菜单'
                        this.menuList[i].subMenus.push(this.form)                                                                                                                             
                        break;        
                      }                                    
                    }      
                  } else {
                    console.log('error submit!!');
                    return false;
                  }
              });   
          }else{
              this.showFrom = true;  
              this.form.name = '子菜单';            
              for(let i=0;i<arr.length;i++) {              
                if(index == i){   
                  if(this.menuList[i].subMenus==undefined) {
                    this.menuList[i].subMenus = []
                  }                       
                  this.menuList[i].subMenus.push(this.form)                                                                               
                  break;        
                }                                    
              }               
          }

      }
          
  }
  
  //多菜单添加的时候需要重置表单
  public resetForm(){
    this.form = {
       key:'text',
       name:'',
       num:'1',
       type:'click',
       selectKey:'text',
       content:'',  //文本内容
       link:'',   //链接
       articleId:'', //图文
       action:''  //微信功能
    }; 
    this.selectKey = 'text';
    this.tableData= [];
    this.ishowImage = false;
  }
 
  //菜单前端删除
  public async deleteMenu(index: any,arr: any,childIndex:any){      
    try {
      await this.$confirm('你确定要删除此菜单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(arr == '1'){ //删除父级菜单    
        if(this.menuList[index].subMenus !=undefined && this.menuList[index].subMenus.length>0){
            this.$message({
              message: '请先删除子菜单',
              type: 'warning'
            });
            return;
        }                                         
        this.menuList.splice(index,1);    
      }else{    
        for(let i=0;i<arr.length;i++) {    //删除父级菜单下面对应的子菜单                                       
          this.menuList[index].subMenus.splice(childIndex,1)       
          break;                                           
        }      
      }     
      this.showFrom = false;
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });     
    }
   
  }
  
  //菜单点击修改
  public menuEdit(row:any){ 
    if(!this.showFrom) { //如果等于false 直接显示，不校验
      this.editData(row) 
      return;
    }
    //点击修改的时候 验证当前表单
    const addRef = (this.$refs.form as any) 
            addRef.validate( async (valid: any) => {
              if (valid) { 
                this.editData(row)                 
              } else {
                console.log('error submit!!');
                return false;
              }
    });    
    
  }
  public async editData(row:any){
    this.showReplay = true;  
    this.showFrom = true;
    this.form = row;  
    this.tableData = [];//重置数据
    this.ishowImage = false;                
    //切换菜单清空提示
    this.$nextTick(() => {
      const clearRef = (this.$refs.form as any) 
      clearRef.clearValidate('replay'); 
    })
    //如果存在子菜单
    if(this.form.subMenus!=undefined && this.form.subMenus!='' && this.form.subMenus.length>0){
      this.showReplay = false;
    }
    if(this.form.selectKey=='news' && this.form.articleId!='' && this.form.articleId!=undefined){       
      this.articleGet()
    }   
    this.selectKey = this.form.selectKey   
  }
  //历史消息事件
  public async handleHistory(): Promise<void> {
    this.dialogFormVisible =  !this.dialogFormVisible;
  }
  public async handleSync() : Promise<void>{  
    const res = await this.wechatMenuService.wxmenuSync(this.agentId);   
    if(res !== undefined){
      this.$message.success('同步微信成功!');
      this.$router.go(0)
    } 
  }
  public getChild(val: any): void { 
    this.tableData = []
    this.tableData.push(val)
    this.form.articleId = val.id  
    this.ishowImage = true
  }
  //二次触发点击事件
  public ishow(){
    let child =  (this.$refs.childImage as wechatImageText)
    child.checkCont()
    child.types = '1'
  }

  //保存
  public async handleSave(formName:any) : Promise<void>{  
     if(!this.showFrom){
       const res = await this.wechatMenuService.wxmenuAdd(this.agentId,this.menuList);      
        this.$alert('保存成功', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$message({
                      type: 'info',
                      message: '保存成功'
                });
            }
        });
        this.wxmenuGet()     
       return;
     }
      const addRef = (this.$refs.form as any)  
      addRef.validate( async (valid: any) => {
          if (valid) {   
            const res = await this.wechatMenuService.wxmenuAdd(this.agentId,this.menuList);      
              this.$alert('保存成功', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.$message({
                      type: 'info',
                      message: '保存成功'
                    });
                  }
              });
               this.wxmenuGet()
              
          } else {
            console.log('error submit!!');
            return false;
          }
      });
      this.count = 0;
  }

  //返回
  public async handleGoBack(): Promise<void> {
    if (this.count > 1) {
      let  _this = this
       this.$confirm('当前信息未保存，返回后信息将丢失，请确认是否返回?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.$router.go(-1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    }else{
      this.$router.go(-1)
    }
   
  }
  public async handleClick(tab:any, event:any): Promise<void> {      
    this.$nextTick(() => {
      const clearRef = (this.$refs.form as any) 
      clearRef.clearValidate('replay'); 
    })
    if(this.selectKey =='text'){ //文本或者图文
      this.form.type = 'click'
      this.form.key = 'text'
      this.form.selectKey = 'text'
    }
    if(this.selectKey =='news'){ //文本或者图文
      this.form.type = 'click'
      this.form.key = 'news'
      this.form.selectKey = 'news'
      if(this.form.articleId!='' && this.form.articleId!=undefined){      
        this.articleGet()
      }   
    }
    if(this.selectKey =='link'){ //链接地址
      this.form.type = 'view'
      this.form.key = 'link'
      this.form.selectKey = 'link'
    }
    if(this.selectKey =='action'){ //微信功能
      this.form.type = this.form.action
      this.form.key = 'action'
      this.form.selectKey = 'action'
    }   
  }

}
