














import { Component, Vue, Prop, Emit, Watch, Model  } from 'vue-property-decorator';
import { Picker } from "emoji-mart-vue";
import faceLink from '@/components/face/faceLink.vue';
@Component({
  components: {
    Picker,
    faceLink
  }
})
export default class face extends Vue {
   @Model('change') readonly val!: any
   @Emit('input')
    change (event: any) {      
      return this.content;
      // return event.target.value
    }

  public content:any = '';
  //是否显示表情包
  public showbq: Boolean = false;
  //是否显示插入链接
  public dialogFormVisible: Boolean = false;
  public I18N = {
  search: '搜索',
  notfound: 'No Emoji Found',
  categories: {
    search: '搜索结果',
    recent: '经常使用',
    smileys: '表情与情感',
    people: '人物与身体',
    nature: '动物与自然',
    foods: '食物与饮料',
    activity: '活动',
    places: '旅行与地理',
    objects: '物品',
    symbols: '符号标志',
    flags: '旗帜',
    custom: 'Custom',
  },
}

@Watch('val', { deep: true })
  handleWatch(val:any){           
    this.content = this.val;          
	}
  
  public created(): void {   
    this.content = this.val;
  }
  public getcancel(e:any) : any {
    this.dialogFormVisible = e
  }
  public getVal(val:any) : any {
    let tags = '<a href="'+val.link+'" >'+val.name+'</a>';  
    console.log(tags)
    this.insertVariable(tags) 
    this.change(window.event)
  }
  public async insertVariable(value:any) {
     const myField :any = document.querySelector("textarea");   
     if(myField.selectionStart || myField.selectionStart === 0) {
       //获取光标停靠的位置
        let startPos = myField.selectionStart;
        let endPos = myField.selectionEnd;

        this.content = myField.value.substring(0, startPos) + value
                    + myField.value.substring(endPos, myField.value.length);
        await this.$nextTick() // 这句是重点, 圈起来
        myField.focus();
        myField.setSelectionRange(endPos + value.length, endPos + value.length);
      } else {
        this.content = value;
      }

    }
  // //微信表情
  public async addFace():Promise<any>{
    this.showbq = true;
  }
  public async handleClick(): Promise<any> {
    this.dialogFormVisible = true;
  }
   // 选择表情
   public async showEmoji(emoji:any) : Promise<any> { 
      const str = emoji.native
      var oText = document.getElementById('textarea')
      var index = this.getCursortPosition(oText)
      this.$nextTick( () => {
        this.insert(oText,str,index)
        this.showbq = false;
      })
    }

    // 插入表情
    public async insert (oText:any,varstr:any,index:any): Promise<any> { 
      var text = this.content
      let startStr = text.substring(0, index)
      let endStr = text.substring(index, text.length)
      this.content = startStr + varstr + endStr
      this.change(window.event)
      setTimeout(() => {
        this.setCaretPosition(oText, index+2)
      }, 10);
    }

    // 获取光标位置
    public getCursortPosition (obj:any) {
      var cursorIndex = 0
      const doc = (document as any)  
      if (doc.selection) {
        obj.focus()
        var range: any = doc.selection.createRange()
        range.moveStart('character', -obj.value.length)
        cursorIndex = range.text.length
      } else if (obj.selectionStart || obj.selectionStart == 0) {
        cursorIndex = obj.selectionStart
      }
      return cursorIndex
    }

    // 设置光标位置
    public setCaretPosition(ctrl: Dict<any>, pos : Dict<any>) {
      if(ctrl.setSelectionRange)
      {
        ctrl.focus();
        ctrl.setSelectionRange(pos,pos);
      }
      else if (ctrl.createTextRange) {
        var range :any = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
      }
  }

  private mounted() {
    // console.log(this.searchConfig)
    // this.searchConfigChange(this.searchConfig);
  }
}
