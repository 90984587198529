import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class WechatMenuService {
  @Inject(HttpService) private http!: HttpService;
  
  
  //查询指定专栏的菜单
  public wxmenuGet(agentId: Dict<any>): Promise<any> {
    return this.http.get(`/api/application/wxmenu/get/${agentId}`);
  }
  
  //同步专栏菜单到企业微信
  public wxmenuSync(agentId: Dict<any>): Promise<any> {
    return this.http.post(`/api/application/wxmenu/syncMenus/${agentId}`);
  }

  //添加菜单
  public wxmenuAdd(agentId: Dict<any>,param: Dict<any>): Promise<any> {
    return this.http.post(`/api/application/wxmenu/addMenu/${agentId}`,param);
  }
  
  //删除专栏菜单
  public wxmenuDelete(agentId: Dict<any>): Promise<any> {
    return this.http.post(`/api/application/wxmenu/delMenu/${agentId}`);
  }
  
  //修改菜单
  public wxmenuUpdate(agentId: Dict<any>): Promise<any> {
    return this.http.post(`/api/application/wxmenu/upMenu/${agentId}`);
  }


}
